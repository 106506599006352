import React, { useContext, useEffect, useState } from "react";
import Head from "next/head";
import { ClickAwayListener, CircularProgress, LinearProgress } from "@material-ui/core";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import moment from "moment";
import NestedDropdown from "../../HelperComponents/NestedDropdown/NestedDropdown";
import NestedMenu from "../../HelperComponents/NestedMenu/NestedMenu";
import NumberFormat from "react-number-format";
import { i18n, Link, Router, withTranslation } from "../../../i18n";
import { useRouter } from "next/router";
import { API_BASE_URL, AppContext, SERVER_TYPE } from "../../../context/appState";
import { Controller, useForm } from "react-hook-form";
import { onlyLetterRegExp } from "../../HelperComponents/regExp";
import { getOption, sendRequest, useMediaQuery } from "../../HelperComponents/functions";

import "./Header.scss";

import favorite_icon from "../../../public/images/favorite_icon.svg";
import basket_mob from "../../../public/images/basket_mob.svg";
import phone_icon from "../../../public/images/phone_btn_new.svg";
import search from "../../../public/images/search_alt.svg";
import close from "../../../public/images/close_alt.svg";
import cart_img from "../../../public/images/cart_alt.svg";
import logo from "../../../public/images/mobile_logo.svg";
import ogLogo from "../../../public/images/Logo 2.png";
import desktop_logo from "../../../public/images/desktop_logo.svg";
import clock from "../../../public/images/header-clock.svg";
import phone from "../../../public/images/header-phone.svg";
import mail from "../../../public/images/header-mail.svg";
import icon2 from "../../../public/images/icon2.png";
import favicon from "../../../public/images/favicon.png";
import sale from "../../../public/images/sale.svg";
import DeliveryIcon from "../../../public/images/delivery_icon.svg";
import DeliveryBoxIcon from "../../../public/images/deliveryBox.svg";
import DiscountIcon from "../../../public/images/discount_icon.svg";
import MobileSearchIcon from "../../../public/images/mobile_search_icon.svg";
import arrowDown from "../../../public/images/arrow-down.svg";
import arrowUp from "../../../public/images/arrow-up.svg";
import fire from "../../../public/images/fire.gif";
import { useWindowSize } from "../../../helpers/changeScreen";
import { daysList, formatTime } from "../../PageBlocks/MoreInfoPages/KontaktyBlock/KontaktyBlock";

import address_footer from "../../../public/images/address_footer.png";

const Header = props => {
    const [state, setState] = useState({
        dialogOpened: false,
        dialogOpenedConfirmation: false,
        in_burger: false,
        result: [],
        search_val: "",
        loaded: false,
        phone_number: ""
    });
    const [res, setRes] = useState([]);
    const [mobSearchStatus, setMobSearchStatus] = useState(false);
    //const [alternative, setAlternative] = useState({ ru: "", uk: "" });
    const [catalogIsOpen, setCatalogIsOpen] = useState(false);
    const [mobCatalogIsOpen, setMobCatalogIsOpen] = useState(false);
    const [purshase_call_status, setPurshase_call_status] = useState(false);

    const [locationUrl, setLocationUrl] = useState("");
    const [lastRoute, setLastRoute] = useState("");

    const {
        createCart,
        contact,
        dispatch,
        postContact,
        dispatchCart,
        total_quantity,
        resetMessage,
        getAllFavorites,
        dispatchDeskFilters,
        URL,
        setLoader
    } = useContext(AppContext);

    const { language } = props.i18n;

    const { register, errors, handleSubmit, control } = useForm();
    const router = useRouter();
    const re = new RegExp(`(` + URL + `|\/ru)`, "g");
    const alternative = {
        ru: /* `${URL}/ru${router.asPath.replace(re, "")}`.includes('?')
            ? `${URL}/ru${router.asPath.replace(re, "")}`.split('?')[0] + '/?' + `${URL}/ru${router.asPath.replace(re, "")}`.split('?')[1]
            : */ `${URL}/ru${router.asPath.replace(
            re,
            ""
        )}`,
        uk: /* `${URL}${router.asPath.replace(re, "")}`.includes('?')
            ? `${URL}${router.asPath.replace(re, "")}`.split('?')[0] + '/?' + `${URL}${router.asPath.replace(re, "")}`.split('?')[1]
            : */ `${URL}${router.asPath.replace(
            re,
            ""
        )}`
    };

    useEffect(() => {
        if (lastRoute.includes("/f/") && !router.asPath.includes("/f/")) {
            // console.log("leave filters page");
            dispatchDeskFilters([], dispatch);
        }


        if (lastRoute === "/checkout/success" && router.asPath === "/checkout") {
            // /checkout/success /checkout
            router.push("/");
        }
        setLastRoute(router.asPath);
    }, [router]);

    const handleOpenBurger = in_burger => {
        setState({ ...state, in_burger });

        switch (in_burger) {
            case true:
                document.body.style.overflow = "hidden";
                if (document.getElementById("bingc-phone-button")) {
                    document.getElementById("bingc-phone-button").classList.add("z0");
                }
                break;

            case false:
                document.body.style.overflow = "";
                if (document.getElementById("bingc-phone-button")) {
                    document.getElementById("bingc-phone-button").classList.remove("z0");
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        window.history.scrollRestoration = "manual";
        moment.locale(language === "ua" ? "uk" : "ru");
        if (localStorage.getItem("token")) {
            getAllFavorites(dispatch);
        }
        window.addEventListener("scroll", handleScroll);
        dispatchCart(JSON.parse(localStorage.getItem("cart")), dispatch, false);

        setLocationUrl(window.location.href);

        const header = document.querySelector(".header");
        let scrollPrev = 0;

        window.addEventListener("scroll", function () {
            let scrolled = window.pageYOffset;

            if (scrolled > 100 && scrolled > scrollPrev) {
                header.classList.add("out");
            } else {
                header.classList.remove("out");
            }
            scrollPrev = scrolled;
        });
    }, []);

    let token = typeof localStorage === "undefined" ? false : localStorage.getItem("token");

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getAllFavorites(dispatch);
        }
    }, [token]);

    useEffect(() => {
        if (mobSearchStatus) {
            document.body.style.overflow = "hidden";
            focusMethod();
        } else {
            document.body.style.overflow = "";
        }
    }, [mobSearchStatus]);

    const toggleDialog = () => setState({ ...state, dialogOpened: !state.dialogOpened });

    useEffect(() => {
        if (contact === "success") {
            toggleDialogConfirmation();
        } else {
            setState({ ...state, username: "", phone_number: "" });
        }
    }, [contact]);

    const toggleDialogConfirmation = () =>
        setState({
            ...state,
            dialogOpened: false,
            dialogOpenedConfirmation: !state.dialogOpenedConfirmation
        });

    const handleScroll = () => {
        let elem = document.getElementById("head_panel");
        if (elem) {
            if (window.scrollY > 164) {
                elem.classList.add("fixed-header");
            } else {
                elem.classList.remove("fixed-header");
            }
        }
    };

    const submitForm = data => {
        setPurshase_call_status(true);
        postContact(data, setPurshase_call_status, dispatch);
    };

    const handleLiveSearch = e => {
        setState({ ...state, search_val: e.target.value });
        if (e.target.value.length >= 3 && SERVER_TYPE === "dev") {
            sendRequest(`${API_BASE_URL}/products/search/${e.target.value}/`, "GET")
                .then(response => response.json())
                .then(data => {
                    setRes(data);
                })
                .catch(error => console.log(error));
        } else {
            setRes([]);
        }
    };

    const redirect = slug => {
        setState({ ...state, search_val: "" });
        setRes([]);
        setMobSearchStatus(false);
        Router.push(`/product/${slug}`);
    };
    const enterRoute = (e, marker) => {
        if (e.keyCode === 13 && state.search_val.length >= 3) {
            setLoader(true, dispatch);
            Router.push(`/search/${state.search_val}`);
            setRes([]);
            setState({ ...state, search_val: "" });
            setMobSearchStatus(false);
        }
        if (marker === "loop" && state.search_val.length >= 3) {
            setLoader(true, dispatch);
            Router.push(`/search/${state.search_val}`);
            setRes([]);
            setState({ ...state, search_val: "" });
            setMobSearchStatus(false);
        }
    };

    const searchToggle = () => {
        setMobSearchStatus(!mobSearchStatus);
    };

    const focusMethod = function getFocus(e) {
        document.getElementById("search").focus();
    };

    const goToRoot = () => {
        setLoader(true, dispatch);
        router.push("/");
        setMobSearchStatus(false);
        handleOpenBurger(false);
    };

    const [phoneList, setPhoneList] = useState(false);
    const openPhoneList = () => setPhoneList(true);
    const closePhoneList = () => setPhoneList(false);

    const [shopListStatus, setShopList] = useState(false);
    const openShopeList = () => setShopList(true);
    const closeShopList = () => setShopList(false);

    const { t, shopList } = props;

    const makeTime = shop => {
        let FT = formatTime;
        if (shop) {
            return (
                <>
                    <p className="working-hours__text">
                        Пн-Пт: {FT(shop.mon_from)} - {FT(shop.mon_to)}
                    </p>
                    <p className="working-hours__text">
                        Сб:{" "}
                        {!!shop.sat_from && !!shop.sat_to ? `${FT(shop.sat_from)} - ${FT(shop.sat_to)}` : t("Выходной")}
                    </p>
                    <p className="working-hours__text">
                        {t("Вс")}:{" "}
                        {!!shop.sun_from && !!shop.sun_to ? `${FT(shop.sun_from)} - ${FT(shop.sun_to)}` : t("Выходной")}
                    </p>
                </>
            );
        } else {
            return "";
        }
    };

    const getPBDepartment = data => {
        let tempArray = [];
        data.forEach(el => {
            tempArray.push({
                label: getOption(`${el.address} `),
                label_ua: getOption(`${el.address_ua} `),
                value: `${el.address} `,
                value_ua: `${el.address_ua} `,
                data: el
            });
        });
        return tempArray;
    };

    const prorabShops = getPBDepartment(shopList);
    const [chosenProrabShop, setChosenProrabShop] = useState(prorabShops[0]);

    useEffect(() => {
        closeShopList();
    }, [chosenProrabShop]);

    const windowSize = useWindowSize();

    //HARD LOADER RESET
    useEffect(() => {
        let timer;
        if (props.loader) {
            timer = setTimeout(() => {
                setLoader(false, dispatch);
            }, 4000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [props.loader]);



    return (
        <header className={`header no_select${mobSearchStatus ? " open_search" : ""}`}>
            <Head>
                <meta charSet="utf-8" />
                <link rel="shortcut icon" type="image/x-icon" href={favicon} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1" />
                <meta name="google-site-verification" content="_s0kVZLKn_YHGTqTMpHnu6t7vRGG1gkqMQBxdQxZ0Lw" />
                <meta name="google-site-verification" content="_s0kVZLKn_YHGTqTMpHnu6t7vRGG1gkqMQBxdQxZ0Lw" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="wa-expert-hash" content="3c13b87b59499684713196e11f5a65f6d1ee9e" />
                <title>{props.temp_title}</title>
                <meta name="description" content={props.temp_description} />
                <meta name="keywords" content={props.temp_keywords} />
                <meta name="google-site-verification" content="4bJsKJ7IvSSMbpfJrEM_cGLCnfGYEvTpv7sd4N6mIvg" />
                <meta property="og:image" content={ogLogo} />
                {!router.asPath.includes("/categories/") && (
                    <>
                        <meta
                            property="og:description"
                            content={`Інтенет магазин товарів для дому, дачі та ремонту «Prorab»`}
                        />
                        <meta
                            property="og:title"
                            content={`Інтенет магазин товарів для дому, дачі та ремонту «Prorab»`}
                        />
                    </>
                )}
                <meta property="og:url" content={locationUrl} />
                <>
                    <link rel="alternate" href={`${alternative.ru}`} hrefLang="ru-ua" />
                    <link rel="alternate" href={`${alternative.uk}`} hrefLang="uk-ua" />
                </>
                <>
                    {language === 'ua'
                        ? <link rel="canonical" href={`${alternative.uk}`} />
                        : <link rel="canonical" href={`${alternative.ru}`} />
                    }
                </>
                {SERVER_TYPE === "prod" && (
                    <script
                        dangerouslySetInnerHTML={{
                            __html:
                                language === "ua"
                                    ? `(function(d) {
                                var s = d.createElement('script');
                                s.defer = true;
                                s.src = 'https://multisearch.io/plugin/12086';
                                if (d.head) d.head.appendChild(s);
                              })(document);`
                                    : `(function(d) {
                                var s = d.createElement('script');
                                s.defer = true;
                                s.src = 'https://multisearch.io/plugin/12085';
                                if (d.head) d.head.appendChild(s);
                              })(document);`
                        }}
                    />
                )}
            </Head>
            <div
                className="fixed-delivery-info"
                dangerouslySetInnerHTML={{
                    __html: props.headerDeliveryMobSnippet[language === "ua" ? "html_ua" : "html"]
                }}
            />
            <div className="user_head">
                <div className="content_block">
                    <button className="burger_btn" onClick={() => handleOpenBurger(true)}>
                        <span />
                        <span />
                        <span />
                    </button>
                    <div className="top_info">
                        <Link href="/o-nas">
                            <a rel="nofollow">{t("О нас")}</a>
                        </Link>
                        <Link href="/oplata-i-dostavka">
                            <a rel="nofollow">{t("Оплата и доставка")}</a>
                        </Link>
                        <Link href="/usloviya-vozvrata-tovara">
                            <a rel="nofollow">{t("Условия возврата товара")}</a>
                        </Link>
                        <Link href="/kontakty">
                            <a rel="nofollow">{t("Контакты")}</a>
                        </Link>
                        <Link href="/uslugi">
                            <a rel="nofollow">{t("Услуги")}</a>
                        </Link>
                    </div>
                    <div className="actions">
                        <div className="lang-switch">
                            <div
                                className={language === "ru" ? "active" : ""}
                                onClick={() => i18n.changeLanguage("ru")}
                            >
                                РУС
                            </div>
                            <div
                                className={language === "ua" ? "active" : ""}
                                onClick={() => i18n.changeLanguage("ua")}
                            >
                                УКР
                            </div>
                        </div>
                        <LogInButtons handleOpenBurger={handleOpenBurger} t={t} />
                    </div>

                    <div className="new_head_logo_box">
                        <img
                            className={`mob_prorab_logo`}
                            src={desktop_logo}
                            alt="PRORAB - text/image"
                            title="PRORAB - logotype"
                            onClick={goToRoot}
                        />
                        {/*<Link href={"/actions"}>*/}
                        {/*    <a className="black-friday">*/}
                        {/*        <img src={fire} alt="Горячие предложения" width="16px" height="30px" />*/}
                        {/*        {t("Акции")}*/}
                        {/*    </a>*/}
                        {/*</Link>*/}

                        <div className="head_search head_search_mob">
                            <div className="content_block">
                                <ClickAwayListener onClickAway={() => setRes([])}>
                                    <div className="search_head">
                                        <div className="search-group">
                                            <div className="search-input">
                                                <label htmlFor="search">
                                                    <img
                                                        src={search}
                                                        alt="PRORAB search image"
                                                        title="PRORAB - поиск"
                                                        width="19px"
                                                    />
                                                </label>
                                                {mobSearchStatus ? (
                                                    <input
                                                        ref={input => input && input.focus()}
                                                        id="search"
                                                        type="text"
                                                        placeholder={t("Поиск по товарам")}
                                                        value={state.search_val}
                                                        onKeyDown={e => enterRoute(e)}
                                                        onChange={e => handleLiveSearch(e)}
                                                        onFocus={e => handleLiveSearch(e)}
                                                    />
                                                ) : (
                                                    <input
                                                        id="search"
                                                        type="text"
                                                        placeholder={t("Поиск по товарам")}
                                                        value={state.search_val}
                                                        onKeyDown={e => enterRoute(e)}
                                                        onChange={e => handleLiveSearch(e)}
                                                        onFocus={e => handleLiveSearch(e)}
                                                    />
                                                )}
                                            </div>
                                            <button onClick={e => enterRoute(e, "loop")}>
                                                <img src={MobileSearchIcon} alt="MobileSearchIcon" />
                                            </button>
                                        </div>
                                        {SERVER_TYPE === "dev" && res && res.length > 0 ? (
                                            <div className="search_dropdown">
                                                {res.map((el, idx) => (
                                                    <div
                                                        className="search_el"
                                                        key={idx}
                                                        onClick={() => redirect(el.slug)}
                                                    >
                                                        <img
                                                            src={el.thumbnail_images[0]}
                                                            alt={`${el.name} - PRORAB${idx !== 0 ? ` image-${idx}` : ""
                                                                }`}
                                                            title={`${el.name} - Внешний вид${idx !== 0 ? ` image-${idx}` : ""
                                                                }`}
                                                        />
                                                        <p>
                                                            {language === "ua" ? el.name_ua : el.name}{" "}
                                                            {el.get_availability !== "Под заказ" && (
                                                                <span>
                                                                    {el.discount_price ? el.discount_price : el.price}
                                                                    {` грн${el.unit ? `/${el.unit}` : ''}`}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>

                        <div className="wrapper_btn">
                            <Link href="/cart">
                                <a
                                    className={`basket_mob${windowSize <= 768 ? " basket_info_wrapper" : ""}`}
                                    onClick={() => handleOpenBurger(false)}
                                >
                                    <img
                                        src={basket_mob}
                                        alt="добавить в корзину image"
                                        title="PRORAB добавить в корзину"
                                    />
                                    {total_quantity ? <span>{total_quantity}</span> : null}
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content_block head_block">
                <div className="logo">
                    <Link href="/">
                        <a rel="nofollow">
                            <img
                                className={`mob_prorab_logo`}
                                src={desktop_logo}
                                alt="PRORAB - text/image"
                                title="PRORAB - logotype"
                            />
                            <img
                                className={`desk_prorab_logo`}
                                src={desktop_logo}
                                alt="PRORAB - text/image"
                                title="PRORAB - logotype"
                            />
                        </a>
                    </Link>
                </div>
                <div className="contact-info">
                    <div className="contact-info__phone">
                        <div className="contact-phones">
                            <img src={phone} alt="contact-info icon" title="Контактные данные" />
                            <span>
                                <a className={`binct-phone-number-1`} href="tel:0800330757">
                                    0 800 33 07 57
                                </a>
                                <button className="contact-phones__btn" onClick={openPhoneList}>
                                    <img src={arrowDown} alt="Стрелка вниз" />
                                    <span className="visually-hidden">{t("Показать телефоны")}</span>
                                </button>
                                <span className="contact-phones__info">{t("Бесплатно с мобильного")}</span>
                            </span>

                            {phoneList && (
                                <ClickAwayListener onClickAway={closePhoneList}>
                                    <ul className="contact-phones__list">
                                        <li>
                                            <img src={phone} alt="Иконка телефона" />
                                            <a href="tel:0800330757">0 800 33 07 57</a>
                                            <button className="contact-phones__btn" onClick={closePhoneList}>
                                                <img src={arrowUp} alt="Стрелка вверх" />
                                                <span className="visually-hidden">{t("Скрыть телефоны")}</span>
                                            </button>
                                        </li>
                                        <li>
                                            <img src={phone} alt="Иконка телефона" />
                                            <a href="tel:0997730444">099 773 04 44</a>
                                        </li>
                                        <li>
                                            <img src={phone} alt="Иконка телефона" />
                                            <a href="tel:0977730444">097 773 04 44</a>
                                        </li>
                                    </ul>
                                </ClickAwayListener>
                            )}
                        </div>
                        <div>
                            <img src={mail} alt="contact-info icon" title="Контактные данные" />
                            <a href="mailto:poltava.prorab@gmail.com">poltava.prorab@gmail.com</a>
                        </div>
                    </div>
                    <button id="order_call" className="call-btn" onClick={toggleDialog}>
                        {t("Заказать звонок")}
                    </button>
                </div>
                <div className="working-hours">
                    <div className="contact-info">
                        <div className="contact-info__phone">
                            <div className="contact-phones">
                                <img src={clock} alt="working-hours clock image" title="График работы" />
                                <span>
                                    <button className={`binct-phone-number-1`} onClick={openShopeList}>
                                        {t("График работы")}:
                                    </button>
                                    <button className="contact-phones__btn" onClick={openShopeList}>
                                        <img src={arrowDown} alt="Стрелка вниз" />
                                        <span className="visually-hidden">{t("Показать телефоны")}</span>
                                    </button>
                                    <span className="contact-phones__info">
                                        {language === "ua" ? chosenProrabShop.value_ua : chosenProrabShop.value}
                                    </span>
                                </span>

                                {shopListStatus && (
                                    <ClickAwayListener onClickAway={closeShopList}>
                                        <ul className="contact-phones__list">
                                            {prorabShops.map((shop, idx) => (
                                                <li>
                                                    <img src={address_footer} alt="Иконка телефона" />
                                                    <button onClick={() => setChosenProrabShop(shop)}>
                                                        {language === "ua" ? shop.value_ua : shop.value}
                                                    </button>
                                                    {idx === 0 && (
                                                        <button className="contact-phones__btn" onClick={closeShopList}>
                                                            <img src={arrowUp} alt="Стрелка вверх" />
                                                            <span className="visually-hidden">
                                                                {t("Скрыть телефоны")}
                                                            </span>
                                                        </button>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </ClickAwayListener>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className="working-hours__clock">
                            <img src={clock} alt="working-hours clock image" title="График работы" />
                        </div>
                        <div className="working-hours__weekday">
                            <p className="working-hours__title">{t("График работы")}:</p>
                            <p className="working-hours__text">Пн-Пт: 8:00 - 19:00</p>
                        </div> */}
                    <div className="working-hours__weekend">
                        {makeTime(chosenProrabShop.data)}
                        {/* <p className="working-hours__text">Пн-Пт: 8:00 - 19:00</p>
                            <p className="working-hours__text">Сб: 08:30 - 19:00</p>
                            <p className="working-hours__text">{t("Вс")}: 08:30 - 18:00</p> */}
                    </div>
                </div>
                <Link href="/cart">
                    <a rel="nofollow" className="head-cart">
                        <img src={cart_img} alt="добавить в корзину image" title="PRORAB добавить в корзину" />
                        {total_quantity ? <span>{total_quantity}</span> : null}
                    </a>
                </Link>
            </div>

            <div className={`head_search${mobSearchStatus ? " showSearch" : ""}`}>
                <div className="content_block">
                    <div className="head_catalog">
                        <button
                            onClick={() => {
                                props.setUseOverlay(true);
                                setCatalogIsOpen(true);
                            }}
                            className="btn_red"
                        >
                            {t("Каталог товаров")}
                        </button>
                        {catalogIsOpen ? (
                            <ClickAwayListener
                                onClickAway={() => {
                                    props.setUseOverlay(false);
                                    setCatalogIsOpen(false);
                                }}
                            >
                                <NestedDropdown
                                    categories={props.categories}
                                    isOpen={catalogIsOpen}
                                    close={() => {
                                        props.setUseOverlay(false);
                                        setCatalogIsOpen(false);
                                    }}
                                    makeListeners={props.makeListeners}
                                    language={language}
                                />
                            </ClickAwayListener>
                        ) : (
                            <NestedDropdown
                                categories={props.categories}
                                isOpen={false}
                                close={() => {
                                    setCatalogIsOpen(false);
                                }}
                                makeListeners={props.makeListeners}
                                language={language}
                            />
                        )}
                    </div>
                    {/*<Link href="/sale">*/}
                    {/*    <a className="head-promo first">*/}
                    {/*        <img src={sale} alt="PRORAB sale percent icon" title="Акции"/>*/}
                    {/*        <p>{t("Акции")}</p>*/}
                    {/*    </a>*/}
                    {/*</Link>*/}
                    {/* <Link href={"/actions"}>
                            <a className="head-promo first black-friday">
                                <img src={fire} alt="PRORAB sale percent icon" title="Черная пятница" />
                                <p>{t("Акции")}</p>
                            </a>
                        </Link> */}
                    <Link href="/uslugi#delivery">
                        <a className="head-promo first">
                            <img src={DeliveryIcon} alt="PRORAB sale percent icon" title="Доставка" width="24px" />
                            <p>{t("Доставка")}</p>
                        </a>
                    </Link>
                    <div
                        className="head-promo deliveryBox last"
                        dangerouslySetInnerHTML={{
                            __html: props.headerDeliverySnippet[language === "ua" ? "html_ua" : "html"]
                        }}
                    />
                    {/* <Link href="/installment">
                            <a className="head-promo last">
                                <img src={DiscountIcon} alt="PRORAB sale percent icon" title="Рассрочка" width="24px" />
                                <p>{t("Рассрочка")}</p>
                            </a>
                        </Link> */}
                    {/* <Link href="/podarochnyy-sertifikat" as="/podarochnyy-sertifikat">
                            <a className="head-promo">
                                <img src={star} alt="PRORAB star icon" title="Подарочный сертификат" />
                                <p>{t("Подарочный сертификат")}</p>
                            </a>
                        </Link> */}

                    <ClickAwayListener onClickAway={() => setRes([])}>
                        <div className="search_head">
                            <div className="search-group">
                                <div className="search-input">
                                    <label htmlFor="search">
                                        <img
                                            src={search}
                                            alt="PRORAB search image"
                                            title="PRORAB - поиск"
                                            width="19px"
                                        />
                                    </label>
                                    {mobSearchStatus ? (
                                        <input
                                            ref={input => input && input.focus()}
                                            id="search"
                                            type="text"
                                            placeholder={t("Поиск по товарам")}
                                            value={state.search_val}
                                            onKeyDown={e => enterRoute(e)}
                                            onChange={e => handleLiveSearch(e)}
                                            onFocus={e => handleLiveSearch(e)}
                                        />
                                    ) : (
                                        <input
                                            id="search"
                                            type="text"
                                            placeholder={t("Поиск по товарам")}
                                            value={state.search_val}
                                            onKeyDown={e => enterRoute(e)}
                                            onChange={e => handleLiveSearch(e)}
                                            onFocus={e => handleLiveSearch(e)}
                                        />
                                    )}
                                </div>
                                <button className="green-btn" onClick={e => enterRoute(e, "loop")}>
                                    {t("Поиск")}
                                </button>
                            </div>

                            {SERVER_TYPE === "dev" && res && res.length > 0 ? (
                                <div className="search_dropdown">
                                    {res.map((el, idx) => (
                                        <div className="search_el" key={idx} onClick={() => redirect(el.slug)}>
                                            <img
                                                src={el.thumbnail_images[0]}
                                                alt={`${el.name} - PRORAB${idx !== 0 ? ` image-${idx}` : ""}`}
                                                title={`${el.name} - Внешний вид${idx !== 0 ? ` image-${idx}` : ""}`}
                                            />
                                            <p>
                                                {language === "ua" ? el.name_ua : el.name}{" "}
                                                {el.get_availability !== "Под заказ" && (
                                                    <span>{el.discount_price ? el.discount_price : el.price}{` грн${el.unit ? `/${el.unit}` : ''}`}</span>
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
            <div className="backdrop" onClick={() => setMobSearchStatus(false)} />
            <div className="burger_catalog mobile_catalog">
                <button
                    onClick={() => {
                        props.setUseOverlay(false);
                        setMobCatalogIsOpen(true);
                    }}
                    className="burger_catalog__btn"
                >
                    {t("Каталог товаров")}
                </button>

                {mobCatalogIsOpen && (
                    <NestedMenu
                        language={language}
                        categories={props.categories}
                        isOpen={mobCatalogIsOpen}
                        close={() => {
                            props.setUseOverlay(false);
                            setMobCatalogIsOpen(false);
                            handleOpenBurger(false);
                        }}
                        closeMenu={() => {
                            setMobCatalogIsOpen(false);
                        }}
                        closeBurger={() => handleOpenBurger(false)}
                        makeListeners={props.makeListeners}
                    />
                )}
            </div>
            <section className={state.in_burger === false ? "burger" : "burger burger--open"}>
                <div className="burger_head">
                    <div className="burger_head__top">
                        <img
                            className="burger_logo"
                            src={logo}
                            alt="burger logo icon"
                            onClick={goToRoot}
                            title="Меню"
                        />
                        <button
                            className="burger_close"
                            onClick={() => {
                                handleOpenBurger(false);
                            }}
                        >
                            Close
                        </button>
                    </div>
                    <div className="burger_head__login">
                        <LogInButtons handleOpenBurger={handleOpenBurger} t={t} />
                        <div className="burger_head__lang-switch">
                            <div
                                className={language === "ru" ? "active" : ""}
                                onClick={() => i18n.changeLanguage("ru")}
                            >
                                РУС
                            </div>
                            <div
                                className={language === "ua" ? "active" : ""}
                                onClick={() => i18n.changeLanguage("ua")}
                            >
                                УКР
                            </div>
                        </div>
                    </div>
                    {/* <div className="burger_head__lang-switch">
                        <div
                            className={language === "ru" ? "active" : ""}
                            onClick={() => i18n.changeLanguage("ru")}
                        >
                            РУС
                        </div>
                        <div
                            className={language === "ua" ? "active" : ""}
                            onClick={() => i18n.changeLanguage("ua")}
                        >
                            УКР
                        </div>
                    </div> */}
                </div>
                <div className="burger_catalog">

                    {
                        <NestedMenu
                            language={language}
                            isInBurger={state.in_burger}
                            categories={props.categories}
                            isOpen={true}
                            close={() => {
                                props.setUseOverlay(false);
                                setMobCatalogIsOpen(false);
                                handleOpenBurger(false);
                            }}
                            closeMenu={() => {
                                setMobCatalogIsOpen(false);
                            }}
                            closeBurger={() => handleOpenBurger(false)}
                            makeListeners={props.makeListeners}
                        />
                    }
                </div>
                <div className="burger_promo">
                    <Link href="/uslugi#delivery">
                        <a className="burger_promo__link" onClick={() => handleOpenBurger(false)}>
                            <img src={DeliveryIcon} alt="PRORAB sale percent icon" title="Доставка" />
                            <span>{t("Доставка")}</span>
                        </a>
                    </Link>
                    <Link href="/installment">
                        <a className="burger_promo__link" onClick={() => handleOpenBurger(false)}>
                            <img src={DiscountIcon} alt="PRORAB sale percent icon" title="Рассрочка" />
                            <span>{t("Рассрочка")}</span>
                        </a>
                    </Link>

                    <Link href="/favorite">
                        <a className="burger_promo__link" onClick={() => handleOpenBurger(false)}>
                            <img src={favorite_icon} alt="PRORAB favorite icon" title="Избранное" />
                            <span>{t("Избранное")}</span>
                        </a>
                    </Link>

                    {/* <Link href="/podarochnyy-sertifikat" as="/podarochnyy-sertifikat">
                            <a className="burger_promo__link" onClick={() => handleOpenBurger(false)}>
                                <img src={star} alt="PRORAB star icon" title="Подарочный сертификат" />
                                <p>{t("Подарочный сертификат")}</p>
                            </a>
                        </Link> */}
                </div>
                <ul className="burger_menu">
                    <li className="burger_menu__item burger_menu__item--arrow">
                        <Link href="/uslugi">
                            <a onClick={() => handleOpenBurger(false)}>{t("Услуги")}</a>
                        </Link>
                    </li>
                    <li className="burger_menu__item">
                        <Link href="/oplata-i-dostavka">
                            <a rel="nofollow" onClick={() => handleOpenBurger(false)}>
                                {t("Доставка и оплата")}
                            </a>
                        </Link>
                    </li>
                    <li className="burger_menu__item">
                        <Link href="/usloviya-vozvrata-tovara">
                            <a rel="nofollow" onClick={() => handleOpenBurger(false)}>
                                {t("Условия возврата товара")}
                            </a>
                        </Link>
                    </li>
                    <li className="burger_menu__item">
                        <Link href="/kontakty">
                            <a rel="nofollow" onClick={() => handleOpenBurger(false)}>
                                {t("Контакты")}
                            </a>
                        </Link>
                    </li>
                    <li className="burger_menu__item">
                        <Link href="/o-nas">
                            <a rel="nofollow" onClick={() => handleOpenBurger(false)}>
                                {t("О нас")}
                            </a>
                        </Link>
                    </li>
                </ul>
                <div className="burger_contact">
                    <div className="burger_contact__wrapper burger_contact__wrapper--big">
                        <img
                            className="burger_contact__icon"
                            src={phone}
                            alt="contact-info icon"
                            title="Контактные данные"
                            width="20px"
                            height="20px"
                        />
                        <div className="burger_contact__phone">
                            <a className={`binct-phone-number-1`} href="tel:0800330757">
                                0 800 33 07 57
                            </a>
                        </div>
                        <span className="burger_contact__info">{t("Бесплатно с мобильного")}</span>
                    </div>
                    <div className="burger_contact__row">
                        <div className="burger_contact__wrapper">
                            <img
                                className="burger_contact__icon"
                                src={phone}
                                alt="contact-info icon"
                                title="Контактные данные"
                                width="20px"
                                height="20px"
                            />
                            <div className="burger_contact__phone">
                                <a href="tel:0977730444">097 773 04 44</a>
                            </div>
                        </div>
                        <div className="burger_contact__wrapper">
                            <img
                                className="burger_contact__icon"
                                src={phone}
                                alt="contact-info icon"
                                title="Контактные данные"
                                width="20px"
                                height="20px"
                            />
                            <div className="burger_contact__phone">
                                <a href="tel:0997730444">099 773 04 44</a>
                            </div>
                        </div>
                    </div>
                    <button className="burger_contact__btn call-btn" onClick={toggleDialog}>
                        {t("Заказать звонок")}
                    </button>
                </div>
            </section>
            <DialogComponent
                open={state.dialogOpened}
                onClose={toggleDialog}
                classes="new-dialog"
                paper_classes="dialog_callback_wrapper"
                close_image={close}
            >
                <div className="dialog_callback">
                    <div className="title">{t("Остались вопросы")}?</div>
                    <div className="sub-title">{t("dialog_callback")}</div>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="input_wrapper">
                            <input
                                name="username"
                                type="text"
                                placeholder={t("Имя")}
                                ref={register({
                                    required: false,
                                    pattern: { value: onlyLetterRegExp },
                                    maxLength: 40
                                })}
                                className={`input_field ${errors.username ? "red" : ""}`}
                            />
                            {errors.username && errors.username.type === "required" && (
                                <span className="error">{t("Обязательное поле")}</span>
                            )}
                            {errors.username && errors.username.type === "pattern" && (
                                <span className="error">{t("Только буквы")}</span>
                            )}
                            {errors.username && errors.username.type === "maxLength" && (
                                <span className="error">{t("Максимум 40 символов")}</span>
                            )}
                        </div>
                        <div className="input_wrapper">
                            <Controller
                                control={control}
                                as={NumberFormat}
                                mask=""
                                format="+############"
                                name="phone_number"
                                defaultValue={380}
                                rules={{
                                    required: true,
                                    minLength: 13
                                }}
                                className={`input_field phone ${errors.phone_number ? "red" : ""}`}
                            />
                            {errors.phone_number && errors.phone_number.type === "required" && (
                                <span className="error">{t("Обязательное поле")}</span>
                            )}
                            {errors.phone_number && errors.phone_number.type === "pattern" && (
                                <span className="error">{t("Формат номера должен быть")} +380XXXXXXXXX</span>
                            )}
                            {errors.phone_number && errors.phone_number.type === "minLength" && (
                                <span className="error">{t("minLength")}</span>
                            )}
                        </div>
                        <button disabled={purshase_call_status} className="call-btn">
                            {purshase_call_status && <CircularProgress color="secondary" />}
                            {t("Заказать звонок")}
                        </button>
                    </form>
                </div>
            </DialogComponent>

            <DialogComponent
                open={state.dialogOpenedConfirmation}
                onClose={() => {
                    toggleDialogConfirmation();
                    resetMessage("", dispatch);
                }}
                paper_classes="dialog_callback_confirmation"
            >
                <div className="dialog_confirmation">
                    <img src={icon2} alt="PRORAB success icon" title="запрос успешно отправлен" />
                    <div className="title">
                        {t("Поздравляем")}! <br /> {t("Ваш запрос успешно отправлен")}
                    </div>
                </div>
            </DialogComponent>
            {props.loader && <LinearProgress color={`secondary`} />}
        </header>
    );
};

const LogInButtons = ({ t, handleOpenBurger }) => {
    const [browserStorageToken, setBrowserStorageToken] = useState(true);
    const { dispatch, setFavorites } = useContext(AppContext);

    const logOutBack = () => {
        setFavorites(dispatch);
        sendRequest(`${API_BASE_URL}/logout/`, "POST").then(() => localStorage.removeItem("token"));
    };

    useEffect(() => {
        setBrowserStorageToken(typeof localStorage === "undefined" ? true : localStorage.getItem("token"));
    }, []);

    return browserStorageToken !== null && browserStorageToken.length !== 0 ? (
        <>
            <Link on href="/my/profile">
                <a className="actions-link" rel="nofollow" >
                    {t("Мой профиль")}
                </a>
            </Link>
            <Link href="/">
                <a className="actions-link" rel="nofollow" onClick={() => logOutBack()}>
                    {t("Выход")}
                </a>
            </Link>
        </>
    ) : (
        <>
            <Link href="/login">
                <a className="actions-link" rel="nofollow" onClick={() => handleOpenBurger(false)} >
                    {t("Вход")}
                </a>
            </Link>

            <Link href="/signup">
                <a className="actions-link" rel="nofollow" onClick={() => handleOpenBurger(false)} >
                    {t("Регистрация")}
                </a>
            </Link>
        </>
    );
};

export default withTranslation("header")(Header);
